import React, { Component } from "react"
import { constants } from "../misc/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneSquareAlt,
  faMobileAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

class Contact extends Component {
  render() {
    return (
      <div id="contact" className="section-spacing">
        <div className="cd-half-width">
          <div className="container borders">
            <div className="columns">
              <div className="column is-12">
                <div className="sectionHeading">
                  <h4 className="is-size-3 centerText">Contact</h4>
                  <hr />
                </div>
                <div className="content">
                  <div className="columns">
                    <div className="column is 4">
                      <h5 className="is-size-5">Contact Information</h5>
                      <div className="left-info">
                        <p className="is-size-6">
                          111 Jennie Lee Drive <br />
                          Overtown <br />
                          Wishaw <br />
                          ML2 0EE <br />
                          <br />
                          <FontAwesomeIcon icon={faPhoneSquareAlt} />
                          &nbsp;
                          {constants.mobileNumber} <br />
                          <FontAwesomeIcon icon={faMobileAlt} />
                          &nbsp;
                          {constants.landlineNumber}
                          <br />
                          <FontAwesomeIcon icon={faEnvelope} />
                          &nbsp;
                          {constants.email}
                        </p>
                      </div>
                    </div>
                    <div className="column is-8">
                      <h5 className="is-size-5">Send a message</h5>
                      <div className="row">
                        <form
                          id="contact-form"
                          action="https://formspree.io/stevenson-roofing@outlook.com"
                          method="POST"
                        >
                          <div className="column is-12">
                            <label className="label" htmlFor="name">
                              Name
                            </label>
                            <input
                              name="name"
                              type="text"
                              className="input"
                              id="name"
                              required
                            />
                          </div>
                          <div className="column is-12">
                            <label className="label" htmlFor="email">
                              Email
                            </label>
                            <input
                              name="_replyto"
                              type="email"
                              className="input"
                              id="email"
                            />
                          </div>
                          <div className="column is-12">
                            <label className="label" htmlFor="phonenumber">
                              Phone number
                            </label>
                            <input
                              name="phone-number"
                              type="text"
                              className="input"
                              id="phonenumber"
                            />
                          </div>
                          <div className="column is-12">
                            <label className="label" htmlFor="message">
                              Message
                            </label>
                            <textarea
                              name="message"
                              rows="6"
                              className="input"
                              id="message"
                            />
                          </div>
                          <input
                            type="hidden"
                            name="_next"
                            value="http://roofinglanarkshire.co.uk/thanks"
                          />
                          <div className="column is-12">
                            <button
                              type="submit"
                              id="form-submit"
                              className="button is-link"
                              value="Send"
                            >
                              Send Message
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
