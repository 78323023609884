import React from "react"
import Layout from "../components/Layout"
import Contact from "../components/Contact"

const seoProps = {
  title: "Contact",
  description:
    "Stevenson Roofing can be contacted through phone, email or by using our contact form on this page.",
  pathname: "/contact",
}

const ContactPage = () => (
  <Layout seoProps={seoProps}>
    <Contact />
  </Layout>
)

export default ContactPage
